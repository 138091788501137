import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';

const Footer2 = () => {
    const [logo, setLogo] = useState(require('./../../images/footerlogo.png'));

    return (
        <>
            <footer className="site-footer footer-large footer-light footer-wide">
                {/* FOOTER BLOCKS START */}
                <div className="footer-top overlay-wraper">
                    <div className="overlay-main" />
                    <div className="container">
                        <div className="row justify-content-between">
                            {/* ABOUT COMPANY */}
                            <div className="col-lg-3 col-md-6 col-sm-6">
                                <div className="widget widget_about">
                                <div className="logo-footer clearfix p-b15">
    <NavLink to={"./"}>
        <img src={logo} alt="Logo" style={{ width: '90px', height: '70px' }} />
    </NavLink>
</div>

                                    <p className="max-w400">
                                    At Jain Life Spaces, we bring your dreams to life with quality, elegance, and dedication, creating spaces that inspire and uplift every day.                                        </p>
                                    <ul className="social-icons mt-social-links">
                                    <li><NavLink to={"#"} className="facebook"><i className="fa fa-facebook" /></NavLink></li>
                                            <li><NavLink to={"#"} className="google"><i className="fa fa-google" /></NavLink></li>
                                            
                                     
                                    </ul>
                                </div>
                            </div>
                            
                            {/* <div className="col-lg-3 col-md-6 col-sm-6">
                                <div className="widget widget_services inline-links">
                                    <h4 className="widget-title">Useful links</h4>
                                    <ul>
                                        <li><NavLink to={"/about"}>About</NavLink></li>
                                        <li><NavLink to={"/project-grid"}>Projects</NavLink></li>
                                        
                                        <li><NavLink to={"/contactus"}>Contact Us</NavLink></li>
                                    </ul>
                                </div>
                            </div> */}
                            {/* CONTACT INFORMATION - Extreme Right */}
                            <div className="col-lg-3 col-md-6 col-sm-6" style={{ marginLeft: '550px' }}>
    <div className="widget widget_address_outer" style={{ textAlign: 'right' }}>
        <h4 className="widget-title">Contact Us</h4>
        <ul className="widget_address" style={{ listStyleType: 'none', padding: 0 }}>
            <li>#5f2 5th floor sampurna chambers Vasavi temple road</li>
            <li>Basavangudi Blore 560004</li>
            <li>jainlifespaces@gmail.com</li>
            <li>+91-9241292412</li>
        </ul>
    </div>
</div>


                        </div>
                    </div>
                </div>
                {/* FOOTER COPYRIGHT */}
                <div className="footer-bottom overlay-wraper">
                    <div className="overlay-main" />
                    <div className="container">
                        <div className="row">
                            <div className="mt-footer-bot-center">
                                <span className="copyrights-text">Website Designed and Managed by Riddhi Infotech-7499391121</span>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    );
};

export default Footer2;
