import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import Navigation from '../Common/Navigation';

const bnr = require('./../../images/background/bg-5.png');

const Header2 = () => {
    const [logo, setLogo] = useState(require('./../../images/LOGO.png'));
    const [isSearchActive, setIsSearchActive] = useState(false);
    const [isQuoteActive, setIsQuoteActive] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const offset = window.scrollY;
            const stickyheader = document.querySelector('.sticky-header');

            if (stickyheader) {
                if (offset >= 100) {
                    stickyheader.classList.add('is-fixed', 'color-fill');
                } else {
                    stickyheader.classList.remove('is-fixed', 'color-fill');
                }
            }
        };

        window.addEventListener('scroll', handleScroll);

        window.updateTopMostParent = (logopath) => setLogo(logopath);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleSearchToggle = () => setIsSearchActive((prev) => !prev);
    const handleQuoteToggle = () => setIsQuoteActive((prev) => !prev);

    return (
        <header className="site-header header-style-2">
            {/* Header Middle */}
            <div className="header-middle bg-white">
    <div className="container">
        <div className="logo-header" style={{ marginRight: '20px' }}> {/* Add margin-right to logo */}
            <NavLink to={"./"}>
                <img src={logo} width={85} height={70} alt="Jain Life Spaces" />
            </NavLink>
        </div>
        <div className="header-info" style={{ marginLeft: '20px' }}> {/* Add margin-left to header-info */}
            <ul>
                <li>
                    <div className="icon-sm">
                        <span className="icon-cell">
                            <i className="fa fa-map-marker" style={{ color: 'black' }} />
                        </span>
                    </div>
                    <div className="icon-content">
                        <strong>Bangalore, India</strong>
                        <span>
                            #5f2 5th floor sampurna chambers Vasavi temple road Basavangudi Blore 560004
                        </span>
                    </div>
                </li>
                <li>
                    <div className="icon-sm">
                        <span className="icon-cell text-primary"><i className="fa fa-phone" style={{ color: 'black' }} /></span>
                    </div>
                    <div className="icon-content">
                        <strong>+91-9241292412</strong>
                        <span>Mon - Saturday: 9.00 to 6.00</span>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</div>

            {/* Sticky Header */}
            <div className="sticky-header main-bar-wraper">
                <div className="main-bar header-botton nav-bg-secondry">
                    <div className="container">
                        {/* NAV Toggle Button */}
                        <button data-target=".header-nav" data-toggle="collapse" type="button" className="navbar-toggle collapsed">
                            <span className="sr-only">Toggle navigation</span>
                            <span className="icon-bar" />
                            <span className="icon-bar" />
                            <span className="icon-bar" />
                        </button>
                        {/* Extra Nav */}
                        <div className="extra-nav">
                           
                            <div className="extra-cell">
    <NavLink to={"#"} className="contact-slide-show" onClick={handleQuoteToggle}>
        <i className="fa fa-angle-left arrow-animation" />
        <span style={{ marginLeft: '5px' }}> Contact </span> {/* Text added here */}
    </NavLink>
</div>

                        </div>
                        {/* Contact Slide */}
                        <div className="contact-slide-hide" style={{ backgroundImage: `url(${bnr})`, right: isQuoteActive ? '0px' : '-500px' }}>
                            <div className="contact-nav">
                                <NavLink to={"#"} className="contact_close" onClick={handleQuoteToggle}>�</NavLink>
                                <div className="contact-nav-form p-a30">
                                    <div className="contact-info m-b30">
                                        <div className="mt-icon-box-wraper center p-b30">
                                            <div className="icon-xs m-b20 scale-in-center"><i className="fa fa-phone" /></div>
                                            <div className="icon-content">
                                                <h5 className="m-t0 font-weight-500">Phone number</h5>
                                                <p>+91-9241292412 </p>
                                            </div>
                                        </div>
                                        <div className="mt-icon-box-wraper center p-b30">
                                            <div className="icon-xs m-b20 scale-in-center"><i className="fa fa-envelope" /></div>
                                            <div className="icon-content">
                                                <h5 className="m-t0 font-weight-500">Email address</h5>
                                                <p>jainlifespaces@gmail.com</p>
                                            </div>
                                        </div>
                                        <div className="mt-icon-box-wraper center p-b30">
                                            <div className="icon-xs m-b20 scale-in-center"><i className="fa fa-map-marker" /></div>
                                            <div className="icon-content">
                                                <h5 className="m-t0 font-weight-500">Address info</h5>
                                                <p>#5f2 5th floor sampurna chambers Vasavi temple road </p>
                                               <p> Basavangudi Blore 560004</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="full-social-bg">
                                        <ul>
                                            <li><NavLink to={"#"} className="facebook"><i className="fa fa-facebook" /></NavLink></li>
                                            <li><NavLink to={"#"} className="google"><i className="fa fa-google" /></NavLink></li>
                                            
                                            
                                        </ul>
                                    </div>
                                    <div className="text-center">
                                        <h4 className="font-weight-600">Designed and Managed by Riddhi Infotech</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Search Form */}
                        <div id="search" className={isSearchActive ? "open" : null}>
                            <span className="close" onClick={handleSearchToggle} />
                            <form role="search" id="searchform" action="/search" method="get" className="radius-xl">
                                <div className="input-group">
                                    <input defaultValue="" name="q" type="search" placeholder="Type to search" />
                                    <span className="input-group-btn"><button type="button" className="search-btn"><i className="fa fa-search arrow-animation" /></button></span>
                                </div>
                            </form>
                        </div>
                        {/* Main Navigation */}
                        <Navigation />
                    </div>
                </div>
            </div>
        </header>
    );
};

export default Header2;
